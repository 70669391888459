<template>
  <div class="echart" id="echart-periodline" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'period',
  data(){
    return {
      data: [{
        "name": "就业",
        "value": 456
      }, {
        "name": "结业",
        "value": 231
      }, {
        "name": "待业",
        "value": 121
      } ]
    }
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-periodline'));
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        title: {
          text: '占比情况',
          textStyle: {
            color: '#f2f2f2',
            fontSize: 10,
          },
          subtextStyle: {
            fontSize: 30,
            color: ['#ff9d19']
          },
          x: 'center',
          y: '22%',
        },
        legend: {
          icon:"circle",
          data: ['就业', '待业','结业'],
          left:'center',
          textStyle: {
            fontSize: 12,
            color: 'rgb(0,253,255,0.6)'
          },
          right: '4%',
        },
        grid: {
          top: '50%',
          left: '4%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['2012','2013','2014','2015','2016'],
          axisLabel: {
            textStyle: {
              color: 'rgb(0,253,255,0.6)'
            },
            formatter: function(params) {
              return params.split(' ')[0]
            }
          }
        },
        yAxis: {
          name: '人数',
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: 'rgb(0,253,255,0.6)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgb(23,255,243,0.3)'
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgb(0,253,255,0.6)'
            }
          }
        },
        series:[{
          name: '新增户数',
          type: 'line',
          color: 'rgba(23, 255, 243)',
          smooth: true,
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(23, 255, 243' + ', 0.3)'
              }, {
                offset: 0.8,
                color: 'rgba(23, 255, 243' + ', 0)'
              }], false),
              shadowColor: 'rgba(0, 0, 0, 0.1)',
              shadowBlur: 10
            }
          },
          symbol: 'circle',
          symbolSize: 5,
          data: [451, 352, 303, 534, 95, 236, 217, 328, 159, 151, 231, 192, 453, 524, 165, 236, 527, 328, 129, 530]
        },
          {
            name: '注销户数',
            type: 'line',
            color: 'rgba(119,61,190)',
            smooth: true,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(119,61,190' + ', 0.3)'
                }, {
                  offset: 0.8,
                  color: 'rgba(119,61,190' + ', 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            symbol: 'circle',
            symbolSize: 5,
            data: [360, 545, 80, 192, 330, 580, 192, 80, 250, 453, 352, 28, 625, 345, 65, 325, 468, 108, 253, 98]
          },    {
            name: '测试用户',
            type: 'line',
            color: 'rgba(65,133,240)',
            smooth: true,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(82,96,176' + ', 0.3)'
                }, {
                  offset: 0.8,
                  color: 'rgba(82,96,176' + ', 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            symbol: 'circle',
            symbolSize: 5,
            data: [388, 452, 205, 152, 456, 365, 452, 96, 236, 123, 526, 45, 233, 455, 23, 422, 563, 263, 145, 120]
          },
          {
            color:['rgb(23, 255, 243)', 'rgb(119,61,190)','rgb(65,133,240)'],
            tooltip: {
              trigger: 'item',
              formatter: '{b} ({d}%)'
            },
            radius: ['20%', '26%'],
            center: ['50%', '25%'],
            type: 'pie',
            label: {
              show:false
            },
            // labelLine: {
            //   normal: {
            //     show: false,
            //     length: 30,
            //     length2: 55
            //   },
            //   emphasis: {
            //     show: false
            //   }
            // },
            data: this.data,

          },]
      };

      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
