<template>
    <div class="head" :style="{height: bodyHight}">
        <!--  标题  -->
        <Row>
            <Col span="24">
                <div class="bt" style="height: 60px;">
                    <img :src="bt" width="100%" height="50px"/>
                    <p class="bt1">就业情况统计分析</p>
                </div>
            </Col>
        </Row>
        <!--  中间  -->
        <Row>
            <!--  左边    -->
            <Col span="7" style="height: 40%;">
                <Row>
                    <div class="admissionsPeople" style="margin-bottom: 15px">
                        <p style="color: rgb(255,255,255);font-size: 26px;">数据统计</p>
                    </div>
                </Row>
                <Row style="height: 88%;">
                    <div class="admissionsPeople1">
                        <div class="sjtjclass">
                            <p>待业人数</p>
                            <p class="NumClass">{{data.dyrsNum}}</p>
                            <p class="sjhbClass">环比去年
                                <Icon type="md-arrow-round-up" style="margin-left: 3%" color="#00CC39"/>
                                <span style="color: #00CC39">{{data.zrjgBfb + '%'}}</span>
                                <histogram></histogram>
                            </p>
                        </div>
<!--                        <img :src="org" width="45" height="45" class="imgClass">-->
                        <img :src="teacher" width="45" height="45" class="imgClass" >
                        <div style="width: 96px;height: 34px;float: right;margin: 35px 43px 15px 85px">
                            <img :src="riseBar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div class="admissionsPeople1">
                        <div class="sjtjclass">
                            <p>结业人数</p>
                            <p class="NumClass">{{data.jieyrsNum}}</p>
                            <p class="sjhbClass">环比去年
                                <Icon type="md-arrow-round-up" style="margin-left: 3%" color="#00CC39"/>
                                <span style="color: #00CC39">{{data.zrjgBfb + '%'}}</span>
                                <histogram></histogram>
                            </p>
                        </div>
<!--                        <img :src="teacher" width="45" height="45" class="imgClass">-->
                        <img :src="student" width="45" height="45" class="imgClass">
                        <div style="width: 96px;height: 34px;float: right;margin: 35px 43px 15px 85px">
                            <img :src="riseBar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div class="admissionsPeople1">
                        <div class="sjtjclass">
                            <p>就业人数</p>
                            <p class="NumClass">{{data.jiuyrsNum}}</p>
                            <p class="sjhbClass">环比去年
                                <Icon type="md-arrow-round-down" style="margin-left: 3%" color="#CC0000"/>
                                <span style="color: #CC0000">{{data.pxxyBfb + '%'}}</span>
                            </p>
                        </div>
<!--                        <img :src="student" width="45" height="45" class="imgClass">-->
                        <img :src="getJob" width="45" height="45" class="imgClass">
                        <div style="width: 96px;height: 34px;float: right;margin: 35px 43px 15px 85px">
                            <img :src="declineBar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div class="admissionsPeople1">
                        <div class="sjtjclass">
                            <p>就业率</p>
                            <p class="NumClass">{{data.jylNum}}</p>
                            <p class="sjhbClass">环比去年
                                <Icon type="md-arrow-round-down" style="margin-left: 3%" color="#CC0000"/>
                                <span style="color: #CC0000">{{data.pxxyBfb + '%'}}</span>
                            </p>
                        </div>
                        <img :src="getJob" width="45" height="45" class="imgClass">
                        <div style="width: 96px;height: 34px;float: right;margin: 35px 43px 15px 85px">
                            <img :src="declineBar" width="100%" height="100%" />
                        </div>
                    </div>
                    <div class="StudentPassRate">
                        <p style="color: rgb(255,255,255);font-size: 26px">本期班级就业率排名</p>
                    </div>
                    <div class="StudentPassRate1">
<!--                        <img :src="borderImg" width="100%" height="300px"/>-->
                            <div
                                    style="display: flex;flex-direction:column;font-size: 16px;color: white;margin-top: 5%;margin-left: 5%;height: 900px;width: 90%" >
                                <div style="display: flex; justify-content: space-between;align-items: center;margin-top: 5px" v-for="item in classData" :key="item.id" :index="item.id">
                                    <img :src="item.img" width="45" height="45"/>
                                    <div style="position: absolute;left: 47px;font-weight: bold">{{item.id}}</div>
                                    <div style="flex:0.3" :style="{color: item.color}">{{item.name}}</div>
                                    <Progress style="flex:0.5" :percent="item.percent" hide-info />
                                </div>
                            </div>
                    </div>
                </Row>
            </Col>
            <!--   中间    -->
            <Col span="10" style="padding: 0 40px">
                <Row style="height: 525px">
                    <div class="org">
                        <div class="org1" :style="{backgroundImage: 'url('+middlePic+')'}"
                             style="background-size: 523px 373px;background-repeat: no-repeat;background-position: center top;">


                            <div style="position: relative;bottom: 50px;">
                                <img :src="btbk" width="97px" height="34px" style="margin-top: 112px">
                                <span style="position: absolute; bottom: 8%; left: 48%; font-size: 12px;color: white">就业学员</span>
                                <span style="position: absolute; bottom: -35%;left: 46%; font-size: 27px;color: #0091F8">{{middleData.jiuyrsNum}}</span>
                            </div>
                            <Divider style="color: #747EF7"/>
                            <div style="position: relative;">
                                <img :src="btbk" width="97px" height="34px">
                                <span style="position: absolute; bottom: 30%; left: 48%;   font-size: 12px;color: white">就业率</span>
                                <span style="position: absolute; bottom: -130%;left: 46%; font-size: 27px;color: #0091F8">{{middleData.jylNum}}</span>
                            </div>
                            <div style="position: relative;right: 200px;top: 150px">
                            <img :src="btbk" width="229px" height="106px">
                            <span style="position: absolute; bottom: 50%; left: 48%;font-size: 14px;color: white">结业学员</span>
                                <span style="position: absolute; bottom: 20%; left: 46%;font-size: 27px;color: #0091F8">{{middleData.jieyrsNum}}</span>
                            </div>
                                <div style="position: relative;top: 39px;left: 185px">
                            <img :src="btbk" width="229px" height="106px">
                            <span style="position: absolute; bottom: 50%; left: 48%;font-size: 14px;color: white">待业学员</span>
                                    <span style="position: absolute; bottom: 20%; left: 48%;font-size: 27px;color: #0091F8">{{middleData.dyrsNum}}</span>
                                </div>
                        </div>
                    </div>
                </Row>
                <Row>
                    <div class="zjsjfxbt">
                        <p style="color: rgb(255,255,255);font-size: 26px">数据统计</p>
                    </div>
                </Row>
                <Row>
<!--                    <div class="zjsjfxnr">-->
<!--                        <img :src="borderImg" width="100%" style="height: 61%"/>-->
<!--                        <div style="position: absolute;bottom: 45%;left: 5%;">-->
<!--                            <div style="display: flex;margin-bottom: 12px;font-size: 16px;color: white" v-for="item in yuanData" :key="item.id" :index="item.id">-->
<!--                                <div class="yuan"></div>-->
<!--                                <div style="margin-left: 10px;margin-right: 250px;">{{item.name}}</div>-->
<!--                                <div>{{item.time}}</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="ageDistribution1">
                        <img :src="borderImg_2" width="100%" height="100%"/>
                    </div>
                    <div style="position: absolute;float: left;overflow: hidden;width: 100%;top: 40%;height: 32%;left: 2%">
                        <div class="marquee-wrap">
                            <div class="marquee-list" :class="{'animate-up': animateUp}">
                                <div style="display: flex;margin-bottom: 12px;font-size: 14px;color: white;" v-for="(item, index) in yuanData" :key="index">
                                    <div class="yuan"></div>
                                    <div style="margin-left: 10px;margin-right: 200px">{{item.name}}</div>
                                    <div style="float: right">{{item.time}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Col>
            <!-- 右边 -->
            <Col span="7">
                <Row>
                    <div class="ageDistribution">
                        <p style="color: rgb(255,255,255);font-size: 26px">培训就业率</p>
                    </div>
                </Row>
                <Row >
                    <div class="ageDistribution1">
                        <img :src="borderImg" width="100%" height="191px"/>
                        <!--   本期就业率   -->
                        <div class="currentPeriod">
                            <current-period-gauge></current-period-gauge>
                        </div>
                        <!--   本年就业率   -->
                        <div class="currentYear">
                            <current-year-gauge></current-year-gauge>
                        </div>

                        <!--   去年就业率   -->
                        <div class="lastYear">
                            <last-year-gauge></last-year-gauge>
                        </div>
                    </div>
                </Row>
                <!--   教师学员学历分布    -->
                <Row>
                    <div class="degreeDistribution">
                        <p style="color: rgb(255,255,255);font-size: 26px">招生、报考、结业趋势</p>
                    </div>
                </Row>

                <Row >
                    <div class="degreeDistribution1">
                        <img :src="borderImg" width="100%" height="593px"/>

                        <div class="tendency2">
                            <period></period>
                        </div>
                    </div>
                </Row>
            </Col>
        </Row>
    </div>
</template>

<script>
    import bt from '../../static/img/标题.png'
    import org from '../../static/img/机构1.png'
    import teacher from '../../static/img/教师1.png'
    import student from '../../static/img/学员1.png'
    import getJob from '../../static/img/就业1.png'
    import studyTime from '../../static/img/学习时长1.png'
    import borderImg from '../../static/img/边框2.png'
    import borderImg_2 from '../../static/img/边框-2.png'
    import earth from '../../static/img/earth.png'
    import histogram from './histogram'

    import declineBar from '../../static/img/declineBar.png'
    import riseBar from '../../static/img/riseBar.png'
    import middlePic from '../../static/img/middlePic.png'
    import btbk from '../../static/img/标题边框.png'
    // import jdt from '../../static/img/进度条.png'
    import lq from '../../static/img/蓝圈.png'
    import zq from '../../static/img/紫圈.png'
    import period from './period'
    import currentPeriodGauge from './currentPeriodGauge'
    import currentYearGauge from './currentYearGauge'
    import lastYearGauge from './lastYearGauge'

    export default {
        name: "test",
        components: {
            histogram,period,currentPeriodGauge,currentYearGauge,lastYearGauge
        },
        data() {
            return {
                // lk
                riseBar:riseBar, //上升柱状图
                declineBar:declineBar, //下降柱状图
                middlePic: middlePic,// 中间大图
                btbk: btbk,// 中间大图的内部图片
                // jdt: jdt,// 进度条
                lq: lq,
                zq: zq,
                animateUp: false,


                bt: bt, //标题图片
                org: org, //机构图片
                teacher: teacher,
                student: student,
                getJob: getJob,
                studyTime: studyTime,
                borderImg: borderImg,
                borderImg_2: borderImg_2,
                earth: earth,
                value: 0,
                timer: '0',//计时器
                bodyHight: 0,
                pwidth: 0,//公告文本的宽度
                windowWidth: document.documentElement.clientWidth,// 设备屏幕的宽度
                data: {
                    dyrsNum: 560,
                    zrjgBfb: 10,
                    jieyrsNum: 3560,
                    jiuyrsNum: 3560,
                    jylNum:83.43,
                    pxxyBfb: 10,
                },
                middleData: {
                    jiuyrsNum: '1520人',
                    jieyrsNum: '1520人',
                    dyrsNum: '520人',
                    jylNum:'80.34%',
                },
                yuanData: [
                    {
                        id: 1,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-01',
                    },
                    {
                        id: 2,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-02',
                    },
                    {
                        id: 3,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-03',
                    },
                    {
                        id: 4,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-04',
                    },
                    {
                        id: 5,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-05',
                    },
                    {
                        id: 6,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-06',
                    },
                    {
                        id: 7,
                        name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                        time: '2021-06-07',
                    },
                    // {
                    //     id: 8,
                    //     name: 'xxx班级张小花学员签约xx机构xxx职位，成功就业',
                    //     time: '2021-06-05',
                    // },
                ],
                classData: [
                    {
                      id: 1,
                      name: '班级A',
                      img: zq,
                      color: '#C69CFF',
                      percent: 95,
                    },
                    {
                        id: 2,
                        name: '班级A',
                        img: zq,
                        color: '#C69CFF',
                        percent: 92,
                    },
                    {
                        id: 3,
                        name: '班级A',
                        img: zq,
                        color: '#C69CFF',
                        percent: 90,
                    },
                    {
                        id: 4,
                        name: '班级B',
                        img: lq,
                        color: '#00CCCC',
                        percent: 90,
                    },
                    {
                        id: 5,
                        name: '班级B',
                        img: lq,
                        color: '#00CCCC',
                        percent: 90,
                    },
                ]
            }
        },
        watch: {},
        methods: {

            scrollAnimate() {
                this.animateUp = true
                setTimeout(() => {
                    this.yuanData.push(this.yuanData[0])
                    this.yuanData.shift()
                    this.animateUp = false
                }, 500)
            }
        },
        mounted() {
            this.timer = setInterval(this.scrollAnimate, 1500);
            this.bodyHight = window.innerHeight + 'px';
            window.onresize = () => {
                return (() => {
                    this.bodyHight = window.innerHeight + 'px';
                })();
            };

        },
        destroyed() {
            clearInterval(this.timer)
        }
    }
</script>

<style scoped>

    .head {
        background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
    }

    .bt {
        position: relative; /*相対定位*/
    }

    .bt1 {
        position: absolute; /*絶対定位*/
        top: 5%;
        left: 45%;
        color: rgb(0, 255, 255);
        font-size: 27px;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
    }

    /*机构分布、综合排名*/
    .admissionsPeople {
        width: 100%;
        height: 40px;
        background-color: rgb(0, 26, 77);
        text-align: center;
        line-height: 40px;
    }

    .admissionsPeople1 {
        width: 100%;
        /*height: 20%;*/
        background-color: rgb(0, 20, 61);
        margin-bottom: 20px;
        height: 103px;
    }

    /*学员合格率*/
    .StudentPassRate {
        width: 100%;
        height: 40px;
        background-color: rgb(0, 26, 77);
        text-align: center;
        line-height: 40px;
        margin-top: 5px;
    }

    /*学员合格率*/
    .StudentPassRate1 {
        width: 100%;
        height: 37%;
        margin-top: 10px;
        position: relative; /*相対定位*/
        background-image: url("../../static/img/边框2.png");
        background-size: 100% 300px;
        background-repeat: no-repeat;
    }

    /*教师学员年龄分布*/
    .ageDistribution {
        width: 100%;
        height: 40px;
        background-color: rgb(0, 26, 77);
        text-align: center;
        line-height: 40px;
    }

    .ageDistribution1 {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        position: relative;
    }

    /*本期就业率*/
    .currentPeriod {
        width: 31%;
        height: 90%;
        position:absolute;/*絶対定位*/
        bottom: 5%;
        left:3%;
    }

    /*本年就业率*/
    .currentYear {
        width: 30%;
        height: 90%;
        position:absolute;/*絶対定位*/
        bottom: 5%;
        left:36%;
    }

    /*去年就业率*/
    .lastYear {
        width: 31%;
        height: 90%;
        position:absolute;/*絶対定位*/
        bottom: 5%;
        left:68%;
    }

    /*教师学员学历分布*/
    .degreeDistribution {
        width: 100%;
        height: 40px;
        background-color: rgb(0, 26, 77);
        text-align: center;
        line-height: 40px;
        margin-top: 20px;
    }

    .degreeDistribution1 {
        width: 100%;
        height: 100%;
        margin-top: 10px;
        position: relative;
    }

    .tendency2 {
        width: 94%;
        height: 90%;
        position:absolute;/*絶対定位*/
        bottom: 0;
        left:15px;
    }
    /*机构*/
    .org {
        width: 100%;
        height: 100%;
        background-color: rgb(0, 20, 61);

    }

    .org1 {
        width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        margin-top: 10px;
    }

    .sjtjclass {
        color: white;
        position: absolute;
        margin: 3% 0 0 18%;
        width: 100%
    }

    .sjhbClass {
        position: relative;
        margin-left: 17%;
        margin-top: 4%;
        font-size: 12px
    }

    .NumClass {
        position: absolute;
        color: #00CCCC;
        font-size: 23px;
        margin-top: 5px;
        float: left;
    }

    .imgClass {
        margin: 17px 0 0 25px;
        float: left
    }

    /*学员合格率*/
    .zjsjfxbt {
        width: 100%;
        height: 40px;
        background-color: rgb(0, 26, 77);
        text-align: center;
        line-height: 40px;
        margin-top: 26px;
    }

    /*学员合格率*/
    .zjsjfxnr {
        width: 100%;
        /*height: 37%;*/
        margin-top: 10px;
        position: relative; /*相対定位*/
    }

    .ivu-divider-horizontal {
        display: block;
        height: 1px;
        margin: 24px 265px;
        width: 32%;
        min-width: 30%;
        clear: both;
        background: #747EF7;
    }

    .yuan {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-top: 7px;

        background: #ffffff;
    }
    .marquee-wrap {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;

    .marquee-list {
    li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
    }

    }
    .animate-up {
        transition: all 0.5s ease-in-out;
        transform: translateY(-40px);
    }

    }
</style>
